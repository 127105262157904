import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import About from './pages/About';
import AuthLayout from './components/layout/AuthLayout';
import Layout from './components/layout/Layout';
import Dashboard from './pages/dashboard/Dashboard';
import DashboardLayout from './components/layout/DashboardLayout';
import UserProfile from './pages/dashboard/Profile';
import ViewUsers from './pages/dashboard/Users';
import Adverts from './pages/dashboard/Adverts';
import Orders from './pages/dashboard/Orders';
import CategoryList from './pages/dashboard/Category';
import AddCategory from './pages/dashboard/AddCategory';
import AdvertDetails from './pages/dashboard/AdvertDetail';
import OrderDetail from './pages/dashboard/OrderDetail';
import UserDetail from './pages/dashboard/UserDetails';
import KYCRequests from './pages/dashboard/KycRequest';
import NotAuthorized from './pages/NotAuthorized';
import DeleteAccount from './pages/DeleteAccount';

const App = () => {
  return (
    <Router>
      <Routes>
      <Route path="/not-authorized" element={<NotAuthorized />} />
        <Route
          path="/*"
          element={
            <Layout>
              <Routes>
                <Route index element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/delete" element={<DeleteAccount />} />
              </Routes>
            </Layout>
          }
        />

        <Route
          path="/auth/*"
          element={
            <AuthLayout>
              <Routes>
                <Route path="/login" element={<Login />} />
              </Routes>
            </AuthLayout>
          }
        />
        <Route
          path="/admin/*"
          element={
            <DashboardLayout>
              <Routes>
                <Route index element={<Dashboard />} />
                <Route path="/profile" element={<UserProfile />} />
                <Route path="/users" element={<ViewUsers />} />
                <Route path="/users/:userId" element={<UserDetail />} />
                <Route path="/adverts" element={<Adverts />} />
                <Route path="/adverts/:advertId" element={<AdvertDetails />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/orders/:orderId" element={<OrderDetail />} />
                <Route path="/categories" element={<CategoryList />} />
                <Route path="/categories/new" element={<AddCategory />} />
                <Route path="/kyc" element={<KYCRequests />} />
              </Routes>
            </DashboardLayout>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
