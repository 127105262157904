import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from 'reactstrap';
import Breadcrumb from '../components/common/Breadcrumb';
import { useNavigate } from 'react-router-dom';

export default function DeleteAccount() {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  const handleDeleteAccount = () => {
    // Logic for deleting the account, e.g., API call
    console.log('Account deleted');
    toast.success('Account deleted');
    toggleModal();
    navigate('/');
  };

  return (
    <>
      <Breadcrumb title="Account" page="Delete Account" />
      <section id="about" className="section-wrapper bg-primary-light">
        <Container>
          <div className="content-inner-1">
            <p>Click on the button bellow to delete your account.</p>
            <Button color="danger" onClick={toggleModal}>
              Delete Account
            </Button>

            {/* Confirmation Modal */}
            <Modal isOpen={modal} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}>
                Confirm Account Deletion
              </ModalHeader>
              <ModalBody>
                Are you sure you want to delete your account? This action cannot
                be undone.
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={handleDeleteAccount}>
                  Yes, Delete
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </Container>
      </section>
    </>
  );
}
